<template>
	<div class="contactUs-box">
        <!-- header组件     -->
		<vheader
        :navId="navId">
		</vheader>
        <!-- header组件     -->
		<!-- banner组件 -->
		<banner 
		:navName="navName"
		:navUrl="navUrl"
		></banner>
		<!-- banner组件 -->
		<div class="contactUs-content">
			<p class="p03">CONTACT US</p>
			<p class="p02">联系我们</p>
			<!-- contact组件 -->
			<contact></contact>
			<!-- contact组件 -->
			<div class="bottom">				
				<h1>在线留言</h1>
				<p class="p01">友情提示：我公司在收到您的咨询后，两个工作日内，将有招商主管与您联系，请您务必认真填写，感谢您对恒安康泰集团的信任。</p>
				<form name="form" autocomplete="on" @submit.prevent="postData" :model="formList">
					<div class="inputBox">
						<div>
							<p>姓名</p>
							<input type="text" name="name" v-model="formList.name">
						</div>
						<div>
							<p>电话</p>
							<input type="text" name="phone" v-model="formList.phone">
						</div>
						<div>
							<p>邮箱</p>
							<input type="text" name="email" v-model="formList.Email">
						</div>
						<div>
							<p>地址</p>
							<input type="text" name="address" v-model="formList.site">
						</div>
						<div>
							<p>产品</p>
							<input type="text" name="product" v-model="formList.product">
						</div>
						<div>
							<p>内容</p>
							<input type="text" name="message" v-model="formList.content">
						</div>
						<button type="button" @click="goContact">提交</button>
					</div>
				</form>
				
			</div>
		</div>
        <!-- footer组件 -->
        <vfooter></vfooter>
        <!-- footer组件 -->
		<GoTop/>
    </div>
</template>

<script>
import banner from '../../components/banner.vue'
import contact from '../../components/contact.vue'
import vheader from '../../components/vheader.vue'
import vfooter from '../../components/vfooter.vue'
import GoTop from '@/components/GoTop.vue'
export default { 
	name: 'contactUs',
	data () {
		return{
			navName:'联系我们',
			navId:7,
			navUrl:'contactUs',
			formList:{
				name:'',
				phone:'',
				Email:'',
				site:'',
				product:'',
				content:''
			}
        }
	},
	props: {
	},
	mounted(){
		
		
	},
	methods:{	
		// async goContact(){
		// 	let formData = new FormData();
        //     formData.append('name', this.name);
        //     formData.append('phone', this.phone);
        //     formData.append('Email', this.Email);
		// 	formData.append('site', this.site);
		// 	formData.append('product', this.product);
		// 	formData.append('content', this.content);
	
        //     let config = {
        //       headers: {
        //         'Content-Type': 'multipart/form-data'
        //       }
        //     }
		// 	this.$axios
		// 	.post("https://biaodaninfo.com/from_info/hengankangtai",
		// 	 formData, config).
		// 	 then(res => {console.log(res)},
		// 	 err => {console.log(err);})
		// }
		// async goContact(){
			
		// 	const {data:res} = await this.$ajax.post('https://biaodaninfo.com/from_info/hengankangtai',this.formList)
        //     console.log("resresresresresres",res)
        //     if(res.code == 200){
		// 		this.$message({
		// 			message: res.msg,
		// 			type: 'success',
		// 		});
		// 		this.formList.name = ''
		// 		this.formList.phone = ''
		// 		this.formList.Email = ''
		// 		this.formList.site = ''
		// 		this.formList.product = ''
		// 		this.formList.content = ''
        //     }else{
		// 		this.$message({
		// 			message: res.msg,
		// 			type: 'warning'
		// 		});
        //     }
		// },
		async goContact(){
			this.$message({
					message: "提交成功",
					type: 'success',
				});
			this.axios.post("https://biaodaninfo.com/from_info/hengankangtai")
			.then((res) => {
				console.log(res)
			})
		},
			
		
	},
	components:{
		banner,
		contact,
		vheader,
		vfooter,
		GoTop
	},
}
</script>

<style scoped lang="less">
// pc端样式
@media screen and (min-width:750px){
	.contactUs-box{
		.contactUs-content{
			width: 1200Px;
			margin: 38Px auto 80Px;	
			.p03{				
                font-size: 42Px;
				font-weight: 700;
                color: #ddd;
                text-align: center;
			}
			.p02{
				font-size: 22Px;
				font-weight: 700;
				margin-bottom: 50Px;
				padding-bottom: 20Px;
				color: #222;
				text-align: center;
				border-bottom: 1Px solid #999;
			}
			.bottom{
				padding: 10Px 30Px 40PX;		

				border: 1Px solid #999;
				margin-top: 50Px;
				h1{
					color: #222;
					margin-top: 25Px;
					font-size: 22Px;
				}
				.p01{
					font-size: 14Px;
					color: #5a5a5a;
					margin: 15Px 0 22Px;
				}
				.inputBox{
					display: flex;
					justify-content: space-between;
					flex-wrap: wrap;
					p{
						font-size: 12Px;
						color: #5a5a5a;
						margin:28Px 0 10Px;
					}
					input{
						width: 529Px;
						height: 40Px;
						border-radius: 5Px;
						border: 1Px solid #DEDEDE;
						padding-left: 10PX;
					}
					button{
						width: 220Px;
						height: 40Px;
						margin:28Px auto 0;
						color: #fff;
						background-color: #093C6C;
						border-radius: 50Px;
						border: 1Px solid #093C6C;
						cursor: pointer;
						transition: all 0.2s;
						&:hover{
							color: #093C6C;
							background: #fff;
						}
					}
				}
			}
		}
	}
}

// 移动端样式
@media screen and (max-width:750px){
	.contactUs-box{
		.contactUs-content{
			width: 95%;
			margin: 15px auto 30px;				
			.p03{				
				font-size: 25px;
				font-weight: 700;
                color: #ddd;
                text-align: center;
			}
			.p02{
				font-size: 20px;
				font-weight: 700;
				margin-bottom: 20px;
				padding-bottom: 15px;
				color: #222;
				text-align: center;
				border-bottom: 1px solid #999;
			}
			.bottom{
				padding: 10px 15px 40px;	
				border: 1px solid #999;
				margin-top: 10px;
				h1{
					color: #222;
					margin-top: 5px;
					font-size: 20px;
				}
				.p01{
					font-size: 13px;
					line-height: 23Px;
					color: #5a5a5a;
					margin: 10px 0 0;
				}
				.inputBox{
					display: flex;
					justify-content: space-between;
					flex-wrap: wrap;
					p{
						font-size: 13px;
						color: #5a5a5a;
						margin:15px 0 2px;
					}
					input{
						width: 323px;
						height: 35px;
						border-radius: 5px;
						border: 1px solid #DEDEDE;
					}
					button{
						width: 160px;
						height: 36px;
						margin:28px auto 0;
						color: #fff;
						background-color: #093C6C;
						border-radius: 50px;
						border: 1px solid #093C6C;
						cursor: pointer;
						transition: all 0.2s;
						&:hover{
							color: #093C6C;
							background: #fff;
						}
					}
				}
			}
		}
	}

}
</style>
