<template>
    <div class="content-box">
        <ul>
            <li v-for="item in contentList" :key="item.index">
                <div class="left" :id="item.listId">
                </div>
                <div class="right">
                    <h1>{{item.name}}</h1>
                    <p>{{item.word}}</p>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
export default { 
	name: 'contact',
	data () {
		return{
            contentList:[
                {
                    imgUrl:require('../assets/img/contact/contact_01.png'),
                    name:'公司地址',
                    word:'北京海淀区上地三街9号金隅嘉华大厦C座707',
                    listId:'list01'
                },
                {
                    imgUrl:require('../assets/img/contact/contact_02.png'),
                    name:'免费服务热线',
                    word:'4001-089-289',
                    listId:'list02'
                },
                {
                    imgUrl:require('../assets/img/contact/contact_02.png'),
                    name:'电话咨询',
                    word:'(010) 62965516',
                    listId:'list02'
                },
                {
                    imgUrl:require('../assets/img/contact/contact_03.png'),
                    name:'邮箱地址',
                    word:'support@hengankangtai.com',
                    listId:'list03'
                },
                {
                    imgUrl:require('../assets/img/contact/contact_03.png'),
                    name:'手机直拨北京',
                    word:'18519001850',
                    listId:'list03'
                },
                {
                    imgUrl:require('../assets/img/contact/contact_03.png'),
                    name:'手机直拨西部',
                    word:'13238406050',
                    listId:'list03'
                },
            ]
        }
	},
	props: {
	},
	methods:{
	},
}
</script>

<style scoped lang="less">
@media screen and (min-width:750px){
    .content-box{
        width: 1200Px;
        margin: auto;
        ul{
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-wrap: wrap;            
            li{     
                &:nth-child(1){
                    margin-bottom: 70Px;
                }
                &:nth-child(1),&:nth-child(4){
                    width: 375Px;                
                }  
                &:nth-child(2),&:nth-child(5){
                    width: 305Px;                
                }  
                &:nth-child(3),&:nth-child(6){
                    width: 294Px;                
                }            
                display: flex;
                justify-content: left;
                align-items: center;
                
                .left{
                    width: 77Px;
                    height: 77Px;
                    border-radius: 50Px;
                    border: 1Px solid #E1E1E1;
                    transition: all 0.2s;                    
                    background-position: center;
                    background-size: 50%;
                    background-repeat: no-repeat;     
                    &:hover{
                        border: 1Px solid #093C6C;   
                        background-image: url(../assets/img/contact/contact_01b.png);
                    }
                }                                       
                #list01{
                    background-image: url(../assets/img/contact/contact_01.png);
                    &:hover{
                        background-image: url(../assets/img/contact/contact_01b.png);
                    }
                }                                                
                #list02{
                    background-image: url(../assets/img/contact/contact_02.png);
                    &:hover{
                        background-image: url(../assets/img/contact/contact_02b.png);
                    }
                }                                               
                #list03{
                    background-image: url(../assets/img/contact/contact_03.png);
                    &:hover{
                        background-image: url(../assets/img/contact/contact_03b.png);
                    }
                }  
                .right{
                    line-height: 33Px;
                    margin-left: 15PX;
                    h1{
                        font-size: 14Px;
                        color:#434343;
                       
                    }
                    p{
                        font-size: 14Px;
                        color:#5a5a5a;
                    }
                }
            }
        }
    }
}
@media screen and (max-width:750px){
    .content-box{
        width: 95%;
        margin: auto;
        ul{
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-wrap: wrap;            
            li{              
                width: 100%;
                display: flex;
                justify-content: left;
                align-items: center;                
                .left{
                    margin-bottom: 20Px;
                    width: 55px;
                    height: 55px;
                    border-radius: 50px;
                    border: 1px solid #E1E1E1;
                    transition: all 0.2s;                    
                    background-position: center;
                    background-size: 50%;
                    background-repeat: no-repeat;     
                    &:hover{
                        border: 1px solid #093C6C;   
                        background-image: url(../assets/img/contact/contact_01b.png);
                    }
                }                                       
                #list01{
                    background-image: url(../assets/img/contact/contact_01.png);
                    &:hover{
                        background-image: url(../assets/img/contact/contact_01b.png);
                    }
                }                                                
                #list02{
                    background-image: url(../assets/img/contact/contact_02.png);
                    &:hover{
                        background-image: url(../assets/img/contact/contact_02b.png);
                    }
                }                                               
                #list03{
                    background-image: url(../assets/img/contact/contact_03.png);
                    &:hover{
                        background-image: url(../assets/img/contact/contact_03b.png);
                    }
                }  
                .right{
                    margin-left: 15px;
                    h1{
                        font-size: 14px;
                        margin-bottom: 5px;
                        color:#434343;
                       
                    }
                    p{
                        font-size: 14px;
                        color:#5a5a5a;
                    }
                }
            }
        }
    }
}
</style>